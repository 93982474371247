<template>
  <p class="wind--text wa--name-value">
    <strong>
      {{ name }}
    </strong>
    <span class="wind--text-divider"></span>
    <span>
      {{ value }}
    </span>
  </p>
</template>
<script>
export default {
  name: 'WaNameValue',
  props: {
    name: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    }
  }
}
</script>