import { http } from './api'

const user = {
  getAdminPassport (loginData){
    return http({
      method: 'post',
      url: '/users/passport',
      data: {
        email: loginData.email,
        password: loginData.password,
        admin: true
      }
    })
  },
  getUserStatistics (){
    return http({
      method: 'get',
      url: '/users/admin-statistics'
    })
  },
  getUser (uid){
    return http({
      method: 'get',
      url: `/users/${uid}`
    })
  },
  find (params){
    return http({
      method: 'post',
      url: '/users/_find',
      data: params
    })
  },
  update (uid, user){
    return http({
      method: 'put',
      url: `/users/${uid}`,
      data: user
    })
  },
  delete (uid){
    return http({
      method: 'delete',
      url: `/users/${uid}`
    })
  }
}

export default user