<template>
  <li :class="['bx--navigation-item', {'bx--navigation-item--icon': hasIcon, 'bx--navigation-item--active': active}]">
    <a class="bx--navigation-link" @click="go" style="cursor: pointer;">
      <div class="bx--navigation-icon" v-if="hasIcon">
        <slot name="menu-icon" />
      </div>
      <slot />
    </a>
  </li>
</template>
<script>
export default {
  name: 'WaSideMenuItem',
  props: {
    hasIcon: {
      type: Boolean,
      default: false
    },
    to: {
      type: Object
    }
  },
  computed: {
    hasLink (){
      return !!this.to
    },
    active (){
      if (this.hasLink) {
        return this.$route.path == this.to.path
      }

      return false
    }
  },
  methods: {
    go (){
      if (this.hasLink) {
        this.$router.push(this.to)
      }

      this.$emit('click')
    }
  }
}
</script>