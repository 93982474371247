<template>
  <li :class="['bx--navigation__category-item', { 'bx--navigation-item--active': active }]">
    <a class="bx--navigation-link" @click="go" style="cursor: pointer;">
      <slot />
    </a>
  </li>
</template>
<script>
export default {
  name: 'WaSideMenuCategoryItem',
  props: {
    to: {
      type: Object
    }
  },
  computed: {
    hasLink (){
      return !!this.to
    },
    active (){
      if (this.hasLink) {
        return this.$route.path == this.to.path
      }

      return false
    }
  },
  methods: {
    go (){
      if (this.hasLink) {
        this.$router.push(this.to)
      }

      this.$emit('click')
    }
  }
}
</script>