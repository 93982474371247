<template>
  <ui-transition-group 
    direction="right" 
    size="md" 
    expressive
    tag="div"
    class="wind--toast-notification">
    <cv-toast-notification
      v-for="t in queue"
      low-contrast
      :key="t.index"
      :kind="t.kind"
      :title="t.title"
      :sub-title="t.subTitle"
      :caption="t.caption"
      @close="close(t)">
    </cv-toast-notification>
  </ui-transition-group>
</template>
<style lang="scss">
.wind--toast-notification {
  position: fixed;
  z-index: 9001;
  top: 3rem;
  right: 0;
}
</style>
<script>
import { CvToastNotification } from '@carbon/vue/src/index'
import { UiTransitionGroup } from '../transitions'
export default {
  name: 'WaToast',
  components: {
    CvToastNotification,
    UiTransitionGroup
  },
  computed: {
    queue (){
      return this.$store.getters['ui/toast-notification/queue']
    }
  },
  methods: {
    show (t){
      this.$store.dispatch('ui/toast-notification/show', t)
    },
    close (t){
      this.$store.dispatch('ui/toast-notification/close', t)
    }
  }
}
</script>