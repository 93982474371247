<template>
  <div 
    :class="[
      'wa--chart-panel wa--panel bx--aspect-ratio',
      `bx--aspect-ratio--${dimension}`
    ]"
    :style="style">
    <slot :data="chartData" :options="chartOptions"></slot>
    <hr class="wind--divider wind--divider--invisible wind--divider--subtle" />
    <span class="wa--chart-panel--title">
      {{ title }}
    </span>
    <!--apexchart height="300" :series="chartData" :options="chartOptions" ref="chart"></apexchart-->
  </div>
</template>
<style lang="scss">
@import 'carbon-components/scss/globals/scss/spacing';
.wa--chart-panel {
  padding: $spacing-lg;
  text-align: center;

  .bx--chart-holder {
    background-color: transparent;
  }
}
</style>
<script>
export default {
  name: 'WaChartPanel',
  components: {},
  props: {
    dimension: {
      type: String,
      default: '1x1'
    },
    data: {
      type: Array,
      required: true
    },/*
    labels: {
      type: Array,
      required: true
    },
    chartType: {
      type: String,
      required: true
    },*/
    options: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  data: () => ({
    /*
    radialBarOptions: {
      chart: {
        type: 'radialBar'
      },
      colors: [purple[70]],
      plotOptions: {
        radialBar: {
          hollow: {
            margin: 10,
            size: '75%'
          },
          dataLabels: {
            showOn: 'always',
            name: {
              offsetY: -30,
              show: true,
              color: gray[30],
              fontSize: '0.8rem'
            },
            value: {
              color: gray[10],
              fontSize: '3rem',
              show: true
            }
          }
        }
      }
    }*/
  }),
  computed: {
    style (){
      let width = 20
      if (this.dimension == '2x1') {
        width = width * 2
      }
      return {
        'width': `${width}rem`
      }
    },
    chartData (){
      return this.data
    },
    chartOptions (){
      return this.options
    }
  },
  created (){
    /*
    if (this.chartType == 'radial-bar') {
      this.chartOptions = this.radialBarOptions
    }
    this.chartOptions.labels = this.labels*/
  },
  watch: {
    
  }
}
</script>