<template>
  <div class="wa--status-panel wa--panel bx--aspect-ratio bx--aspect-ratio--2x1">
    <span class="wa--status-text">
      <slot />
    </span>
    <span class="wind--text-divider"></span>
    <span :class="['wa--status-indicator', `wa--status-${status}`]">
      {{ statusText }}
    </span>
  </div>
</template>
<style lang="scss">
@import '@carbon/colors/scss/index';

.wa--status-panel {
  width: 20rem;
  text-align: center;

  span {
    line-height: 10rem;
    font-weight: 900;
  }
}

.wa--status-online {
  color: $green-50;
}
.wa--status-offline {
  color: $red-50;
}
</style>
<script>
export default {
  name: 'WaStatusPanel',
  props: {
    status: {
      type: String,
      required: true,
      validator: (value) => {
        return ['online', 'offline', ''].indexOf(value) !== -1
      }
    }
  },
  computed: {
    statusText (){
      if (this.status == 'online') {
        return '在线'
      }
      if (this.status == 'offline') {
        return '不在线'
      }
      return '无信息'
    }
  }
}
</script>