import { http } from './api'

const reliability = {
  calculate (fids, datestring){
    return http({
      method: 'post',
      url: '/reliability/_calculate',
      data: {
        fids: fids,
        datestring: datestring
      }
    })
  }
}

export default reliability