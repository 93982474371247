import jwt_decode from 'jwt-decode'

const auth = {
  namespaced: true,
  state: {
    loggedIn: false,
    jwt: null
  },
  getters: {
    'logged-in': (state) => {
      return state.loggedIn
    },
    'jwt': (state) => {
      return state.jwt
    },
    'expired': (state) => {
      if (state.jwt == null) {
        return true
      }
      const decoded = jwt_decode(state.jwt)
      const now = new Date().getTime() / 1000
      if (decoded.exp <= now) {
        return true
      } else {
        return false
      }
    }
  },
  mutations: {
    ['fill'] (state, jwt) {
      state.loggedIn = true
      state.jwt = jwt
    },
    ['clear'] (state) {
      state.loggedIn = false
      state.jwt = null
    }
  },
  actions: {
    ['login'] (context, jwt) {
      return new Promise( (resolve) => {
        context.commit('fill', jwt)
        resolve()
      } )
    },
    ['logout'] (context) {
      return new Promise( (resolve) => {
        context.commit('clear')
        resolve()
      } )
    }
  }
}

const userStore = {
  namespaced: true,
  modules: {
    auth: auth
  }
}

export default userStore