import axios from 'axios'
import config from '../config'

import vuexStore from '../store'
import router from '../router'

const http = axios.create({
  baseURL: config.apiBaseURL
})

const http2 = axios.create({
  baseURL: config.apiBaseURL
})

http.interceptors.request.use( (config) => {
  // Set authorization header for JWT auth
  // before each request
  const jwt = vuexStore.getters['user/auth/jwt']
  if (jwt) {
    config.headers['Authorization'] = `Bearer ${jwt}`
  }
  return config
} )
http.interceptors.response.use( (response) => {
  return response.data
}, (error) => {
  if (error.response){
    // Centrally handle response errors
    if (error.response.status == 403){
      vuexStore.dispatch('ui/toast-notification/show', {
        kind: 'error',
        title: '无权限',
        caption: error.response.data.error.description
      })
      router.back()
    } else if (error.response.status == 401) {
      // Except login request to /user/passport
      const url_exception = [
        '/users/passport'
      ]
      if ( !url_exception.includes(error.response.config.url) ) {
        vuexStore.dispatch('user/auth/logout')
        vuexStore.dispatch('ui/modal/open', {
          title: '登录已过期',
          message: error.response.data.error.description,
          confirmText: '重新登陆',
          onClose: () => {
            router.push('/login')
          }
        })
      }
    } else if (error.response.status != 404) {
      if (config.debugApiError) {
        vuexStore.dispatch('ui/toast-notification/show', {
          kind: 'error',
          title: '出错',
          caption: error.response.data.error.description
        })
      }
    }
    return error.response.data
  } else {
    console.log(error)
    return false
  }
})

export { http, http2 }
