<template>
  <div 
    class="bx--navigation">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'WaSideMenu'
}
</script>