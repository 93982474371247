<template>
  <ui-transition appear direction="top" size="md">
    <cv-modal
      :visible="showModal"
      :kind="config.kind"
      :size="config.size"
      :auto-hide-off="config.autoHideOff"
      close-aria-label="关闭窗口"
      @modal-shown="modalShown"
      @modal-hidden="modalHidden"
      @primary-click="confirm"
      @secondary-click="cancel"
      @modal-hide-request="hide">
      <template v-if="isLabel" v-slot:label>
        {{ config.label }}
      </template>
      <template v-slot:title>
        {{ config.title }}
      </template>
      <template v-if="hasContent" v-slot:content>
        <p v-if="isMessage">
          {{ config.message }}
        </p>
        <p v-if="isCopyInfo">
          {{ config.copyInfo }}
        </p>
      </template>
      <template v-if="isCancelButton" v-slot:secondary-button>
        {{ config.cancelText }}
      </template>
      <template v-if="isConfirmButton" v-slot:primary-button>
        {{ config.confirmText }}
      </template>
    </cv-modal>
  </ui-transition>
</template>
<script>
import { CvModal } from '@carbon/vue/src/index'
import { UiTransition } from '../transitions'
export default {
  name: 'WaModal',
  components: {
    CvModal,
    UiTransition
  },
  computed: {
    config (){
      return this.$store.getters['ui/modal/config']
    },
    isLabel (){
      return this.config.label !== undefined
    },
    hasContent (){
      return this.config.message !== undefined || this.config.copyInfo !== undefined
    },
    isMessage (){
      return this.config.type == 'message'
    },
    isCopyInfo (){
      return this.config.type == 'copy-info'
    },
    isCancelButton (){
      return this.config.cancelText !== undefined
    },
    isConfirmButton (){
      return this.config.confirmText !== undefined
    },
    showModal (){
      return this.$store.getters['ui/modal/visible']
    }
  },
  methods: {
    modalShown (){
      if (this.config.onShown) {
        this.config.onShown()
      }
      this.$emit('modal-shown')
    },
    modalHidden (){
      this.$emit('modal-hidden')
    },
    confirm (){
      if (this.config.onConfirm){
        this.config.onConfirm()
      }
      this.hide()
      this.$emit('confirm')
    },
    cancel (){
      this.hide()
      this.$emit('cancel')
    },
    hide (){
      if (this.config.onClose) {
        this.config.onClose()
      }
      this.$store.dispatch('ui/modal/close')
    }
  }
}
</script>