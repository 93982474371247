import { http } from './api'

const data = {
  forwarding (){
    return http({
      method: 'get',
      url: '/tencent-id-map'
    })
  },
  createForwarding (fid, batch_id){
    return http({
      method: 'put',
      url: `/tencent-id-map/${fid}`,
      data: {
        batch_id: batch_id
      }
    })
  },
  deleteForwarding (fid){
    return http({
      method: 'delete',
      url: `/tencent-id-map/${fid}`
    })
  }
}

export default data