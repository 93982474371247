<template>
  <transition
    :appear="appear"
    :name="`wind--ui-transition__${direction}-${size}-${type}-${motion}`"
    :mode="mode"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @after-leave="afterLeave">
    <slot></slot>
  </transition>
</template>
<script>
import transitionMixins from './mixins'

export default {
  name: 'UiTransition',
  mixins: [transitionMixins]
}
</script>
