<template>
  <li :class="['bx--navigation-item', {'bx--navigation-item--icon': hasIcon}]">
    <div :class="['bx--navigation__category', {'bx--navigation__category--expanded': expanded}]">
      <button class="bx--navigation__category-toggle" @click="toggleCategory">
        <div class="bx--navigation-icon" v-if="hasIcon">
          <slot name="menu-icon" />
        </div>
        <div class="bx--navigation__category-title">
          <slot />
          <svg aria-hidden="true" width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
            <path d="M16 22L6 12l1.414-1.414L16 19.172l8.586-8.586L26 12 16 22z" />
          </svg>
        </div>
      </button>
      <ul class="bx--navigation__category-items" @expand="onExpand">
        <slot name="category-items" />
      </ul>
    </div>
  </li>
</template>
<script>
export default {
  name: 'WaSideMenuCategory',
  props: {
    hasIcon: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    expanded: false
  }),
  methods: {
    toggleCategory (){
      this.expanded = !this.expanded
    },
    onExpand (){
      if (!this.expanded) {
        this.toggleCategory()
      }
    }
  }
}
</script>