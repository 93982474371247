import { fast01, fast02, moderate01, moderate02, slow01, slow02 } from '@carbon/motion'

const transitionMixins = {
  props: {
    appear: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'out-in',
      validator: (value) => {
        return [
          'out-in',
          'in-out'
        ].indexOf(value) !== -1
      }
    },
    direction: {
      type: String,
      default: 'top',
      validator: (value) => {
        return [
          'top',
          'left',
          'bottom',
          'right'
        ].indexOf(value) !== -1
      }
    },
    size: {
      type: String,
      default: 'lg',
      validator: (value) => {
        return [
          'xs',
          'sm',
          'md',
          'lg',
          'xl',
          '2xl'
        ].indexOf(value) !== -1
      }
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => {
        return [
          'default',
          'fade'
        ].indexOf(value) !== -1
      }
    },
    expressive: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    sizeToDuration: {
      'xs': 'fast01',
      'sm': 'fast02',
      'md': 'moderate01',
      'lg': 'moderate02',
      'xl': 'slow01',
      '2xl': 'slow02'
    },
    durations: {
      'fast01': Number(fast01.split('ms')[0]),
      'fast02': Number(fast02.split('ms')[0]),
      'moderate01': Number(moderate01.split('ms')[0]),
      'moderate02': Number(moderate02.split('ms')[0]),
      'slow01': Number(slow01.split('ms')[0]),
      'slow02': Number(slow02.split('ms')[0])
    }
  }),
  computed: {
    duration (){
      return this.durations[this.sizeToDuration[this.size]]
    },
    motion (){
      if ( this.expressive ) {
        return 'expressive'
      } else {
        return 'productive'
      }
    }
  },
  methods: {
    beforeEnter (){
      this.$emit('before-enter')
    },
    enter () {
      this.$emit('enter')
    },
    afterEnter (){
      this.$emit('after-enter')
    },
    beforeLeave (){
      this.$emit('before-leave')
    },
    afterLeave (){
      this.$emit('after-leave')
    }
  }
}

export default transitionMixins