<template>
  <div id="main">
    <app-layout>
      <router-view />
    </app-layout>
  </div>
</template>
<style lang="scss">
@import '@carbon/themes/scss/themes';

$carbon--theme: $carbon--theme--g100;
@include carbon--theme();

// import Carbon styles here
@import 'carbon-components/scss/globals/scss/styles.scss';

// Admin main styles
@import './styles/main.scss';
// Transition styles
@import './transitions/styles.scss';
</style>
<script>
import AppLayout from './layouts/app-layout'
export default {
  name: 'AdminApp',
  components: {
    AppLayout
  },
  metaInfo: {
    title: '管理员',
    meta: [
      { charset: 'utf-8' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' }
    ]
  }
}
</script>