<template>
  <div>
    <ui-transition
      appear
      direction="top"
      size="md">
      <cv-header aria-label="Wind Admin">
        <cv-skip-to-content></cv-skip-to-content>
        <button 
          v-if="loggedIn"
          :class="['bx--header__menu-triger', 'bx--header__action', {'bx--header__action--active': menuShown}]"
          @click="toggleMenu">
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="bx--navigation-menu-panel-collapse-icon" width="20" height="20" viewBox="0 0 32 32"><path d="M24 9.4L22.6 8 16 14.6 9.4 8 8 9.4 14.6 16 8 22.6 9.4 24 16 17.4 22.6 24 24 22.6 17.4 16 24 9.4z"></path></svg>
          <svg focusable="false" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" class="bx--navigation-menu-panel-expand-icon" width="20" height="20" viewBox="0 0 20 20"><path d="M2 14.8H18V16H2zM2 11.2H18V12.399999999999999H2zM2 7.6H18V8.799999999999999H2zM2 4H18V5.2H2z"></path></svg>
        </button>
        <cv-header-name :to="{ path: '/' }" prefix="水">
          Admin
        </cv-header-name>
      </cv-header>
    </ui-transition>
    <ui-transition
      appear
      direction="left"
      size="md">
      <wa-side-menu v-if="menuShown && loggedIn">
        <wa-side-menu-section>
          <wa-side-menu-items>
            <wa-side-menu-item :to="{ path: '/dashboard' }">
              主面板
            </wa-side-menu-item>
            <wa-side-menu-category
              category-id="wa--category-farms">
              水电场
              <template v-slot:category-items>
                <wa-side-menu-category-item :to="{ path: '/farms/search' }">
                  检索水电场
                </wa-side-menu-category-item>
                <wa-side-menu-category-item :to="{ path: '/farms/reliability' }">
                  管理月报
                </wa-side-menu-category-item>
              </template>
            </wa-side-menu-category>
            <wa-side-menu-category>
              用户
              <template v-slot:category-items>
                <wa-side-menu-category-item :to="{ path: '/users/search' }">
                  检索用户
                </wa-side-menu-category-item>
              </template>
            </wa-side-menu-category>
            <wa-side-menu-item :to="{ path: '/servers' }">
              服务器
            </wa-side-menu-item>
          </wa-side-menu-items>
        </wa-side-menu-section>
        <wa-side-menu-section>
          <wa-side-menu-items>
            <wa-side-menu-item @click="logoutPrompt">
              退出登录
            </wa-side-menu-item>
          </wa-side-menu-items>
        </wa-side-menu-section>
      </wa-side-menu>
    </ui-transition>
    <cv-content class="wind--content">
      <component :is="layout">
        <ui-transition
          appear
          direction="bottom"
          size="md">
          <slot />
        </ui-transition>
      </component>
    </cv-content>
    <wa-modal />
    <wa-toast />
  </div>
</template>
<script>
import { UiTransition } from '../transitions'
import {
  WaSideMenu,
  WaSideMenuSection,
  WaSideMenuItems,
  WaSideMenuItem,
  WaSideMenuCategory,
  WaSideMenuCategoryItem,
  WaModal,
  WaToast
} from '../components'
import {
  CvHeader,
  CvHeaderMenuButton,
  CvSkipToContent,
  CvHeaderName,
  CvContent
} from '@carbon/vue/src/index'
export default {
  name: 'AppLayout',
  components: {
    UiTransition,
    WaSideMenu,
    WaSideMenuSection,
    WaSideMenuItems,
    WaSideMenuItem,
    WaSideMenuCategory,
    WaSideMenuCategoryItem,
    WaModal,
    WaToast,
    CvHeader,
    CvHeaderMenuButton,
    CvSkipToContent,
    CvHeaderName,
    CvContent
  }, 
  data: () => ({
    menuShown: false
  }),
  computed: {
    layout (){
      const layout = this.$route.meta.layout || 'default-layout'
      return () => import(`./${layout}`)
    },
    loggedIn (){
      return this.$store.getters['user/auth/logged-in']
    }
  },
  watch: {
    '$route' (){
      if (this.menuShown) {
        this.menuShown = false
      }
    }
  },
  methods: {
    toggleMenu (){
      this.menuShown = !this.menuShown
    },
    logoutPrompt (){
      this.$store.dispatch('ui/modal/open', {
        title: '确定退出登录？',
        confirmText: '退出登录',
        cancelText: '取消',
        onConfirm: this.logout
      })
    },
    logout (){
      this.$store.dispatch('user/auth/logout').then( () => {
        this.$store.dispatch('ui/toast-notification/show', {
          title: '已退出登录',
          kind: 'success'
        })
        this.$router.push('/')

        // Reset menu shown
        this.menuShown = false
      })
    }
  }
}
</script>