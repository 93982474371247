import { http } from './api'

const farm = {
  get (fid){
    return http({
      method: 'get',
      url: `/farms/${fid}`
    })
  },
  historicalData (fid, start_ts, end_ts){
    return http({
      method: 'get',
      url: `/farms/${fid}/historical-data`,
      params: {
        start_ts: start_ts,
        end_ts: end_ts
      }
    })
  },
  getFarmStatistics (){
    return http({
      method: 'get',
      url: '/farms/admin-statistics'
    })
  },
  search (params){
    return http({
      method: 'post',
      url: '/farms/_find',
      data: params
    })
  },
  update (farm){
    return http({
      method: 'put',
      url: `/farms/${farm._id}`,
      data: farm
    })
  },
  delete (fid){
    return http({
      method: 'delete',
      url: `/farms/${fid}`
    })
  },
  relatedUsers (fid, include_relationships){
    return http({
      method: 'get',
      url: '/relationships',
      params: {
        by: 'farm',
        id: fid,
        include_relationships: include_relationships
      }
    })
  },
  bindUser (fid, uid, access){
    return http({
      method: 'put',
      url: '/relationships',
      data: {
        type: 'user-farm',
        uid: uid,
        fid: fid,
        access: access
      }
    })
  },
  unbindUser (rid){
    return http({
      method: 'delete',
      url: `/relationships/${rid}`
    })
  },
  getCalculable (){
    return http({
      method: 'get',
      url: `/farms/calculable`
    })
  }
}

export default farm