const farm = {
  namespaced: true,
  state: {
    params: {}
  },
  getters: {
    'params': (state) => {
      return state.params
    }
  },
  mutations: {
    ['memorize'] (state, params) {
      state.params = params
    }
  }
}

const searchStore = {
  namespaced: true,
  modules: {
    farm: farm
  }
}

export default searchStore