import Vue from 'vue'

const toastNotification = {
  namespaced: true,
  state: {
    queue: [],
    defaultConfig: {
      index: null,
      kind: 'info'
    }
  },
  getters: {
    'queue': (state) => {
      return state.queue
    }
  },
  mutations: {
    ['add'] (state, t) {
      // Add index
      t.index = new Date().getTime()
      state.queue.unshift(t)
    },
    ['remove'] (state, t) {
      state.queue = state.queue.filter( qItem => qItem.index !== t.index )
    }
  },
  actions: {
    ['show'] (context, t) {
      let config = {
        ...context.state.defaultConfig,
        index: new Date().getTime()
      }
      t = {
        ...config,
        ...t
      }
      context.commit('add', t)
      setTimeout( () => {
        context.commit('remove', t)
      } , 10000)
    },
    ['close'] (context, t) {
      context.commit('remove', t)
    }
  }
}

const modal = {
  namespaced: true,
  state: {
    config: {},
    defaultConfig: {
      autoHideOff: true,
      kind: null,
      size: 'default',
      label: undefined,
      title: '请注意',
      message: undefined,
      copyInfo: undefined,
      cancelText: undefined,
      confirmText: undefined,
      type: 'message'
    },
    visible: false
  },
  getters: {
    'visible': (state) => {
      return state.visible
    },
    'config': (state) => {
      return state.config
    }
  },
  mutations: {
    ['show'] (state, config) {
      // spread
      state.config = {
        ...state.defaultConfig,
        ...config
      }
      state.visible = true
    },
    ['hide'] (state) {
      state.visible = false
    }
  },
  actions: {
    ['open'] (context, config) {
      context.commit('show', config)
    },
    ['close'] (context) {
      context.commit('hide')
    }
  }
}

const timer = {
  namespaced: true,
  state: {
    map: {}
  },
  getters: {
    'start': (state) => (name) => {
      return state.map[name].start
    },
    'finish': (state) => (name) => {
      return state.map[name].finish
    },
    'exist': (state) => (name) => {
      return !!(state.map[name])
    },
    'finished': (state) => (name) => {
      return state.map[name].finish - new Date().getTime() <= 0
    },
    'left': (state) => (name) => {
      return state.map[name].finish - new Date().getTime()
    }
  },
  mutations: {
    ['set'] (state, params){
      Vue.set(state.map, params.name, {
        start: params.start,
        finish: params.finish
      })
    },
    ['reset'] (state, name){
      Vue.set(state.map, name, undefined)
    }
  }
}

const uiStore = {
  namespaced: true,
	modules: {
		'toast-notification': toastNotification,
    'modal': modal,
    'timer': timer
	}
}

export default uiStore
