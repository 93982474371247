<template>
  <div class="wa--big-number wa--panel bx--aspect-ratio bx--aspect-ratio--1x1">
    <div class="wa--big-number--container">
      <span class="wa--big-number--number" :aria-label="number">
        {{ newNumber }}
      </span>
      <hr class="wind--divider wind--divider--invisible" />
      <span class="wa--big-number--description">
        {{ description }}
      </span>
    </div>
  </div>
</template>
<style lang="scss">
.wa--big-number {
  width: 20rem;
  text-align: center;
  vertical-align: middle;
  position: relative;

  .wa--big-number--container {
    margin: 0 auto;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);

    .wa--big-number--number {
      font-size: 4rem;
    }
  }
}
</style>
<script>
export default {
  name: 'WaBigNumber',
  props: {
    number: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isAbbr: false,
    newNumber: 0,
    suffixes: ["", "k", "m", "b"]
  }),
  methods: {
    abbreviate (){
      this.newNumber = this.number
      let suffixNum = 0
      if (this.newNumber >= 1000) {
        this.isAbbr = true
      }
      while (this.newNumber >= 1000) {
        this.newNumber = this.newNumber / 1000
        suffixNum = suffixNum + 1
      }
      this.newNumber = this.newNumber.toPrecision(3)

      this.newNumber = `${this.newNumber}${this.suffixes[suffixNum]}`
    }
  },
  created (){
    this.abbreviate()
  },
  watch: {
    number (){
      this.abbreviate()
    }
  }
}
</script>