import Vue from 'vue'

import VueMeta from 'vue-meta'

import AdminApp from './App.vue'

import router from './router'

import vuexStore from './store'

import api from './api'

// Validation Rules
import './vee-validate'

// Carbon Components
import CarbonComponentsVue from '@carbon/vue'

// Charts
import chartsVue from '@carbon/charts-vue'
import '@carbon/charts-vue/node_modules/@carbon/charts/styles-g100.min.css'

// Configure date picker localization globally
import flatpickr from 'flatpickr'
import { Mandarin } from 'flatpickr/dist/l10n/zh'

flatpickr.localize(Mandarin)

Vue.config.productionTip = false

Vue.use(VueMeta)
Vue.use(CarbonComponentsVue)
Vue.use(chartsVue)

Vue.prototype.$api = api

new Vue({
  router,
  store: vuexStore,
  render: (h) => h(AdminApp)
}).$mount('#app')