import { http } from './api'

const calculation = {
  progress (cid){
    return http({
      method: 'get',
      url: `/calculations/${cid}`
    })
  }
}

export default calculation