<template>
  <span>
    <slot :data="data" :data-copy="dataCopy"></slot>
  </span>
</template>
<script>
export default {
  name: 'WaScope',
  data: () => ({
    data: {},
    dataCopy: {}
  }),
  methods: {
    set (data){
      this.data = data
      // Create a shallow copy (remove reactivity)
      this.dataCopy = JSON.parse(JSON.stringify(data))
    },
    update (name, value){
      this.data[name] = value
      this.dataCopy = JSON.parse(JSON.stringify(this.data))
    }
  }
}
</script>