<template>
  <transition-group
    :appear="appear"
    :name="`wind--ui-transition-group__${direction}-${size}-${type}-${motion}`"
    :tag="tag"
    :class="classname"
    :stagger="stagger"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @after-leave="afterLeave">
    <slot></slot>
  </transition-group>
</template>
<script>
import transitionMixins from './mixins'

export default {
  name: 'UiTransitionGroup',
  mixins: [transitionMixins],
  props: {
    tag: String,
    classname: String,
    stagger: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    staggerList: []
  }),
  methods: {
    beforeEnter (el){
      if (this.stagger) {
        let delay = Number(el.dataset.tgindex) * this.duration
        el.style.transitionDelay = delay + 'ms'
        this.staggerList[Number(el.dataset.tgindex)] = delay
      }
      this.$emit('before-enter')
    },
    afterEnter (el){
      // If stagger, after-enter event
      // remove transition delay to not affect other css animations
      // emitted after last item transition finished
      if (this.stagger) {
        el.style.transitionDelay = null
        if (Number(el.dataset.tgindex) == this.staggerList.length - 1) {
          setTimeout( () => {
            this.$emit('after-enter')
          }, 100 )
        }
      }
    }
  }
}
</script>
