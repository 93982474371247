import { extend } from 'vee-validate'
import { required, email, min, max, numeric, integer, oneOf } from 'vee-validate/dist/rules'

extend('required', {
	...required,
	message: '{_field_} 必填'
})

extend('email', {
	...email,
	message: '请填写有效的邮箱'
})

extend('min', {
	...min,
	message: (fieldName, placeholders) => {
		return `${fieldName} 至少 ${placeholders.length} 个字符`
	}
})

extend('max', {
  ...max,
  message: (fieldName, placeholders) => {
    return `${fieldName} 至多 ${placeholders.length} 个字符`
  }
})

extend('oneOf', {
  ...oneOf,
  message: '{_field_} 必须为合法选项之一'
})

extend('numeric', {
	...numeric,
	message: '{_field_} 只可填写数字'
})

extend('integer', {
  ...integer,
  message: '请在 {_field_} 填写整数'
})

extend('number', {
  validate (value){
    return typeof value == 'number' && !Number.isNaN(value)
  },
  message: '请在 {_field_} 填写数字'
})

extend('non-negative', {
  validate (value){
    return !(value < 0)
  },
  message: '{_field_} 不可小于零'
})

extend('positive', {
  validate (value){
    return (value > 0)
  },
  message: '{_field_} 需大于零'
})

extend('datestring', {
  validate (value){
    
    if ( !/^\d{4}-\d{2}-\d{2}$/.test(value) ) {
      return false
    }
    let da = value.split('-')
    let day = parseInt(da[2], 10)
    let month = parseInt(da[1], 10)
    let year = parseInt(da[0], 10)

    // Check for ranges
    if ( year < 1970 || month == 0 || month > 12) {
      return false
    }

    let monthLength = [ 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31 ]

    // Adjust for leap years
    if ( year % 400 == 0 || (year % 100 != 0 && year % 4 == 0) ) {
      monthLength[1] = 29
    }

    return day > 0 && day <= monthLength[month - 1]
  },
  message: '请在 {_field_} 填写正确日期'
})

extend('arrayMax', {
  validate (value, { length }){
    return value.length <= length
  },
  params: ['length'],
  message: (fieldName, placeholders) => {
    return `${fieldName} 中最多包含 ${placeholders.length} 条项目`
  }
})

extend('integersarray', {
  validate (value){
    return Array.isArray(value) && !value.some((n) => !Number.isInteger(Number(n)) )
  },
  message: '请在 {_field_} 填写整数'
})

extend('idealturbines', {
  validate (value, { target }){
    return !value.some( (n) => !Number(n)>0 && !( Number(n) <= target ) )
  },
  message: (fieldName, placeholders) => {
    return `${fieldName} 中的编号需要在 0-${placeholders.target} 之间`
  }
})

// Custom password rule
extend('password', {
  validate (value){
    return /^[a-zA-Z0-9_]+$/g.test(value)
  },
  message: '{_field_} 仅可以有 大小写英文字母、数字、下划线'
})

// Confirm value is the same
extend('confirm', {
  params: ['target'],
  validate (value, { target }){
    return value === target
  },
  message: '{_field_} 与 {target} 不一致。'
})

// Only mainland china phone numbers
extend('phone', {
  validate (value){
    return /^1[3-9]([0-9]{9})$/g.test(value)
  },
  message: '{_field_} 不是有效中国大陆电话号码'
})

// Mainland Chinese ID
extend('identification', {
  validate (value){
    return /(^[1-9][0-9]{5}(18|19|([23][0-9]))[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{3}[0-9Xx]$)|(^[1-9][0-9]{5}[0-9]{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)[0-9]{3}$)/g.test(value)
  },
  message: '{_field_} 不是有效中国大陆身份证号'
})