import Vue from 'vue'
import VueRouter from 'vue-router'
import vuexStore from './store'

const routes = [
  {
    path: '/',
    component: () => import('./views/Start'),
    meta: {
      layout: 'pop-layout'
    }
  },
  {
    path: '/dashboard',
    component: () => import('./views/Dashboard'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/farms/search',
    component: () => import('./views/FarmsSearch'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/farms/reliability',
    component: () => import('./views/FarmsReliability'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/farms/:fid/historical-data',
    component: () => import('./views/FarmHistoricalData'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/users/search',
    component: () => import('./views/UsersSearch'),
    meta: {
      layout: 'default-layout'
    }
  },
  {
    path: '/servers',
    component: () => import('./views/Servers'),
    meta: {
      layout: 'default-layout'
    }
  },
]


const publicRoutes = [
  '/'
]

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes
})

vuexStore.commit('initialize-persistent-storage')

router.beforeEach( (to, from, next) => {
  
  // Check JWT expiry before each request
  const expired = vuexStore.getters['user/auth/expired']
  const jwt = vuexStore.getters['user/auth/jwt']

  if ( expired && jwt != null) {
    // Commit user auth clear
    vuexStore.commit('user/auth/clear')
  }

  if ( !publicRoutes.includes(to.path) ) {
    // Protected paths
    if (vuexStore.getters['user/auth/logged-in']) {
      next()
    } else {
      if (expired && jwt != null) {
        vuexStore.dispatch('ui/modal/open', {
          title: '登录过期',
          message: '请重新登录。',
          confirmText: '好的',
          cancelText: '取消'
        })
      }

      if ( jwt == null ) {
        vuexStore.dispatch('ui/modal/open', {
          title: '无权访问',
          message: '此页面需登陆后访问。',
          confirmText: '登录',
          cancelText: '取消'
        })
      }

      next('/')
    }
  }

  next()

} )

export default router