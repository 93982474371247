import { http } from './api'

const turbine = {
  historicalData (tid, start_ts, end_ts){
    return http({
      method: 'get',
      url: `/turbines/${tid}/historical-data`,
      params: {
        start_ts: start_ts,
        end_ts: end_ts
      }
    })
  }
}

export default turbine