import { http } from './api'

const server = {
  databaseWelcome (){
    return http({
      method: 'get',
      url: '/database'
    })
  },
  getDatabaseStatus (){
    return http({
      method: 'get',
      url: '/database/up'
    })
  },
  getWindprocStatus (){
    return http({
      method: 'get',
      url: '/windproc/status'
    })
  }
}

export default server