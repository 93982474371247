import store from 'store'
import config from '../config'

const storagePlugin = (vuexStore) => {
  vuexStore.subscribe( (mutation, state) => {
    mutation
    store.set(config.persistentStorageName, JSON.stringify(state))
  })
}

export default storagePlugin