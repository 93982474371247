import Vue from 'vue'
import Vuex from 'vuex'
import storagePlugin from './storage-plugin'
import uiStore from './ui-store'
import userStore from './user-store'
import searchStore from './search-store'
import store from 'store'
import config from '../config'
import { version } from '../../package.json'

console.log(version)

Vue.use(Vuex)

const vuexStore = new Vuex.Store({
  state: {
    version: ''
  },
  mutations: {
    ['initialize-persistent-storage'] (state) {
      let persistentStorage = store.get(config.persistentStorageName)
      if (persistentStorage == undefined) {
        state.version = version
        return
      }
      persistentStorage = JSON.parse(persistentStorage)
      if (persistentStorage.version == version) {
        this.replaceState(
          Object.assign(state, persistentStorage)
        )
      } else {
        state.version = version
      }
    }
  },
  modules: {
    ui: uiStore,
    user: userStore,
    search: searchStore
  },
  plugins: [storagePlugin]
})

export default vuexStore
